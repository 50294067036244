import { createSelector } from 'reselect';
import find from 'lodash/find';
import get from 'lodash/get';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import compact from 'lodash/compact';

import { MOBILE, SMALL_MOBILE, WIDE_DESKTOP } from '../constants/breakpoints';
import {
  SUMO_5310,
  CAT_277_V3,
  HULU_13632,
  HULU_13876,
  HULU_13873,
  HULU_14131,
  HULU_14425,
  WEB_20035,
} from '../constants/experiments';
import { SPRINTV2, PARTNERSHIP } from '../constants/partners';
import {
  NOAH,
  SASH,
  LIVE_ONLY,
  ADS_HULU_DISNEY_ESPN,
  ADS_LIVE_DISNEY_ESPN,
  BASE_COMPONENT_IDS,
  BUNDLE_PLANS_TAB,
  LIVE,
  NOADS_LIVE_DISNEY_ESPN,
  NOADS_HULU_DISNEY_ESPN,
  PSEUDO_2P,
  DUO_PREMIUM,
  DUO_BASIC,
} from '../constants/plans';
import * as routes from '../constants/routes';
import { hasAssignment } from './config';
import {
  getPartnerFlow,
  getIsPartnerFlow,
  getIsRokuPacFlow,
  getIsLiveOnlyFlow,
  getStartPathname,
  getQuery,
  getClient,
  getPathname,
  getIsVIPFlow,
  getIsSprintV2PartnerFlow,
} from './flow';
import { getPartnerOfferContext } from './partners';
import {
  isFeatureEnabled,
  getIsFlashSaleOn,
  getIsDuoBundlesFeatureEnabled,
} from './featureFlag';
import { getEligibility } from './sprintV2';
import { getSelectedPlan } from './user';
import { getControlText, getEnabledUpsellPageFlows } from './siteconfig';
import { filterPlans, orderPlans } from '../utils/planSelectUtils';

// This function can become "state => state.config.plans" if the pseudo_2p_plan becomes a real plan from splat.
export const getPlans = state => {
  const plansFromSplat = get(state, 'config.plans', []);

  // Only add the pseudo2PPlan if we aren't in any special High Intent Sign Up flows where we send back a limited # of plans
  const pseudo2PPlanConfig = getControlText(state, 'pseudo_2p_plan') || null;
  const isNormalFlow = plansFromSplat.length > 3;

  const finalPlans = compact([
    ...plansFromSplat,
    isNormalFlow ? pseudo2PPlanConfig : null,
  ]);

  return finalPlans;
};

// Get the tabIndex from state for the selected plan tab
export const getPlanTabIndex = state => state.flow.selectedPlanTabIndex;

export const getIsBundlePlansTab = createSelector(
  [getPlanTabIndex],
  planTabIndex => planTabIndex === BUNDLE_PLANS_TAB
);

const getWindowIsMobile = (state, props) => {
  const breakpoint = get(props, 'windowSize.breakpoint');

  return breakpoint === MOBILE || breakpoint === SMALL_MOBILE;
};

export const getWindowIsDesktop = (state, props) =>
  get(props, 'windowSize.breakpoint') === WIDE_DESKTOP;

const hasBadgesAssignment = state => hasAssignment(state, HULU_13632.BADGES);

const hasSASHBundleBadgeAssignment = state =>
  hasAssignment(state, HULU_13876.BEST_VALUE);

const hasTabsOnMWAssignment = state =>
  hasAssignment(state, HULU_13873.TABS_ON_MW);

export const getIsSASHOrDuoBasicFlashSale = (state, plan) =>
  (plan.identifier === SASH || plan.identifier === DUO_BASIC) &&
  getIsFlashSaleOn(state) &&
  plan.displayDiscount !== null;

const hasValuePropBulletsAssignment = state =>
  hasAssignment(state, HULU_14131.VALUE_PROP_BULLETS);

const hasSufWebDefault3UpAssignment = state =>
  hasAssignment(state, WEB_20035.SUF_WEB_DEFAULT_3UP);

const hasSufDefault3UpAssignment = state =>
  hasAssignment(state, HULU_14425.SUF_DEFAULT_3UP);

export const getPreselectedPlan = createSelector([getPlans], plans =>
  plans.length === 1 ? plans[0] : null
);

export const getPreselectedPlanProgramId = createSelector(
  [getPreselectedPlan],
  plan => (plan === null ? null : plan.subscription.promotion.programId)
);

export const findPlanById = (id, plans = []) =>
  find(plans, plan => plan.id === id);

export const findPlanBySubIdentifier = (subIdentifier, plans = []) =>
  find(plans, plan => plan.subIdentifier === subIdentifier);

export const findPlanByComponentIds = (state, componentIds) => {
  const plans = getPlans(state);
  return plans.find(plan => isEqual(plan.componentIds, componentIds)) || null;
};

export const getDefaultPlan = state => {
  const partnerFlow = getPartnerFlow(state);
  let defaultBundleId;

  if (partnerFlow === SPRINTV2.FLOW) {
    defaultBundleId = get(
      getEligibility(state),
      'response.defaultBundleId',
      null
    );
  } else if (partnerFlow === PARTNERSHIP.FLOW) {
    defaultBundleId = get(getPartnerOfferContext(state), 'bundleId', null);
  }

  if (!defaultBundleId) {
    return null;
  }

  const plan = findPlanById(defaultBundleId, state.config.plans);
  return !plan || plan.identifier === SASH ? null : plan;
};

// Query parameter whitelist -- successful if any element is present as a query parameter.
const THREE_UP_QUERY_PARAMS = [
  'from',
  'partner',
  'device_code',
  'promotion_code',
  'partner_code',
  'vip_code',
  'human_code',
];

// Client whitelist -- successful if any element matches ALL key/value-pairs with the client state.
const THREE_UP_CLIENT_PARAMS = [
  { partner: 'sprint' },
  { partner: 'hulu', subpartner: 'roku_web' },
];

/**
 * Determines if the current flow belongs to a partner flow or to a high intent
 * flow by checking if the query or client parameters match one of the known
 * partner or HISUF parameters.
 */
const getIsPartnerOrHighIntentFlow = createSelector(
  [getQuery, getClient],
  (query = {}, client = {}) =>
    THREE_UP_QUERY_PARAMS.some(param => query[param]) ||
    THREE_UP_CLIENT_PARAMS.some(param =>
      Object.entries(param).every(([key, value]) => value === client[key])
    )
);

/**
 * Selector to show the 3up view if the experiment HULU-14425 is active and is not the Svod page,
 * or if the flow is a partner or high intent flow, or if it's a OneHulu page.
 *
 * @returns {boolean} True when asserts to show the 3up view.
 */
export const getShow3UpView = createSelector(
  [
    hasSufDefault3UpAssignment,
    hasSufWebDefault3UpAssignment,
    getStartPathname,
    getIsPartnerOrHighIntentFlow,
  ],
  (
    hasSufDefault3Up,
    hasSufWebDefault3Up,
    startPathname,
    isPartnerOrHighIntentFlow
  ) => {
    const pathname = startPathname.toLowerCase();
    const isOneHuluPage = pathname === routes.GO_ONE_HULU;
    return (
      ((hasSufDefault3Up || hasSufWebDefault3Up) &&
        pathname !== routes.GO_SVOD) ||
      isPartnerOrHighIntentFlow ||
      isOneHuluPage
    );
  }
);

/**
 * Filter plans for display on the plan select page based on the url, client, and query parameters.
 * If only one plan is returned, user is directed to the Create Account page with that plan selected.
 */
export const getFilteredPlans = createSelector(
  [
    getPlans,
    getStartPathname,
    getWindowIsDesktop,
    getPlanTabIndex,
    hasTabsOnMWAssignment,
    getIsSprintV2PartnerFlow,
    getShow3UpView,
    hasSufWebDefault3UpAssignment,
    getIsDuoBundlesFeatureEnabled,
  ],
  (
    plans,
    startPathname,
    isDesktop,
    planTabIndex,
    showTabsOnMobile,
    isSprintV2Flow,
    show3UpView,
    hasSuf3UpAssignment,
    isDuoBundlesEnabled
  ) => {
    let filtered;
    let order;
    const pathname = startPathname.toLowerCase();
    const hasTabs = showTabsOnMobile || isDesktop;
    const showDefault3upExperiment =
      hasSuf3UpAssignment &&
      [routes.ROOT, routes.PLAN_SELECT].includes(pathname);

    switch (pathname) {
      case routes.GO_LIVE_ONLY:
        filtered = filterPlans(plans, ADS_LIVE_DISNEY_ESPN, LIVE_ONLY);
        order = [ADS_LIVE_DISNEY_ESPN, LIVE_ONLY];
        break;
      case routes.GO_SASH:
        filtered = filterPlans(plans, SASH);
        order = [SASH];
        break;
      case routes.GO_NOAH:
        filtered = filterPlans(plans, NOAH);
        order = [NOAH];
        break;
      case routes.GO_CURIOSITY:
        filtered = filterPlans(plans, ADS_LIVE_DISNEY_ESPN);
        order = [ADS_LIVE_DISNEY_ESPN];
        break;
      case routes.GO_SVOD:
      case routes.GO_ONE_HULU:
      default: {
        const show3UpAsDefault = showDefault3upExperiment || show3UpView;

        filtered = getTabsFilteredPlans(
          plans,
          show3UpAsDefault,
          isSprintV2Flow
        );
        if (showTabsOnMobile) {
          order = getTabsOnMobilePlansOrder(isDesktop, planTabIndex);
        } else {
          const currentTabIndex = hasTabs ? planTabIndex : undefined;
          const showDuoBundlesOn3Up =
            isDuoBundlesEnabled &&
            filtered.some(plan => plan.identifier === DUO_BASIC) &&
            filtered.some(plan => plan.identifier === DUO_PREMIUM);

          order = getTabsPlansOrder(
            currentTabIndex,
            isSprintV2Flow,
            show3UpAsDefault,
            showDuoBundlesOn3Up
          );
        }
      }
    }

    const sorted = orderPlans(filtered, order);
    return sorted.length ? sorted : plans;
  }
);

export const getPageTitleClass = (state, ownProps) => {
  if (get(state, 'misc.shouldShowPlanSelectMessage', false)) {
    return 'page__title--with-plan-message';
  }
  if (getPathname(state, ownProps) === routes.PLAN_OPTIONS) {
    return 'page__title--options';
  }
  return '';
};

/**
 * Check if any of the plans are DSS (excluding ADS_LIVE_DISNEY_ESPN) to show the Bundle Tab.
 * DSS plans are not part of plans related to high intent, device/partner, or wholesale flows
 */
export const getPlansHaveDisneySuperBundle = createSelector([getPlans], plans =>
  plans.some(plan => {
    const isSASHLiveBundle = plan.identifier === ADS_LIVE_DISNEY_ESPN;
    return plan.disneySuperBundle && !isSASHLiveBundle;
  })
);

/**
 * Checks if experiment HULU-13632 is active and user has treatment assignment.
 *
 * @returns True if user has badges assignment present, false otherwise
 */
export const getShowBundleBadges = createSelector(
  [hasBadgesAssignment],
  isBadgesTreatment => isBadgesTreatment
);

/**
 * Check if experiment HULU-13632 is active with treatment assignment
 * Also check if the view is Mobile so it'll help to select SASH bundle as
 * default card
 *
 * @returns True if experiment is on and on mobile, false otherwise
 */
export const getShowSashBundleAsDefault = createSelector(
  [getShowBundleBadges, getWindowIsMobile],
  (showBundleBadges, isMobile) => showBundleBadges && isMobile
);

/**
 * Check if experiment HULU-13873 is active and user has the Show Tabs on Mobile
 * Web treatment assignment.
 *
 * @returns {boolean} True if the user is assigned to the treatment
 */
export const getShowTabsOnMobileWeb = createSelector(
  [hasTabsOnMWAssignment],
  isTabsOnMWTreatment => isTabsOnMWTreatment
);

/**
 * Checks if experiment HULU-14131 is active user is on 2-up or 3-up.
 *
 * @returns {boolean} True when Plan Select should show the experiment treatment
 */
export const getShowValuePropBullets = createSelector(
  [hasValuePropBulletsAssignment, getStartPathname],
  (hasValuePropBullets, startPathname) => {
    const pathname = startPathname.toLowerCase();
    return (
      (pathname === routes.GO_SVOD || pathname === routes.GO_ONE_HULU) &&
      hasValuePropBullets
    );
  }
);

/**
 * Add classNames according to displayed plans.
 * Check if the displayed plans' length is 3 (three-grouped plans) or 6
 * (basic and bundle plans) to push 'plans--one-hulu', otherwise push 'plans--on-demand'.
 * If LIVE plans, push 'plans--live-only'.
 * If in mobile view and experiment HULU_13873, push 'plans--mobile-tabs'
 * If in Value Prop Bullets experiment HULU-14131, push 'plans--value-prop-bullets'
 */
export const getPlanSelectClass = createSelector(
  [getFilteredPlans, hasTabsOnMWAssignment, getShowValuePropBullets],
  (filteredPlans, hasTabsOnMobile, showValuePropBullets) => {
    const classList = [];
    const plansLength = filteredPlans.length;

    classList.push(`plans--${plansLength}-total`);

    if (plansLength % 3 === 0) {
      classList.push('plans--one-hulu');
    } else if (
      filterPlans(filteredPlans, ADS_LIVE_DISNEY_ESPN, LIVE_ONLY).length === 2
    ) {
      classList.push('plans--live-only');
    } else if (plansLength % 2 === 0) {
      classList.push('plans--on-demand');
    }

    if (hasTabsOnMobile) {
      classList.push('plans--mobile-tabs');
    } else if (showValuePropBullets) {
      classList.push('plans--value-prop-bullets');
    }
    return classNames(classList);
  }
);

export const getShouldSkipPlanSelect = createSelector(
  [getFilteredPlans, getPartnerFlow],
  (plans, partnerFlow) =>
    plans.length === 1 &&
    partnerFlow !== SPRINTV2.FLOW &&
    partnerFlow !== PARTNERSHIP.FLOW
);

const getIsStudentDiscountFeatureEnabled = state =>
  isFeatureEnabled(state, 'student-discount');

/**
 * Check if student discount feature is enabled and the user is in a normal
 * (not VIP or Partner) flow.
 */
export const getShouldShowStudentDiscountLink = createSelector(
  [getIsStudentDiscountFeatureEnabled, getIsVIPFlow, getIsPartnerFlow],
  (isStudentDiscountFeatureEnabled, isVIPFlow, isPartnerFlow) => {
    return isStudentDiscountFeatureEnabled && !isVIPFlow && !isPartnerFlow;
  }
);

/**
 * Check if the Duo Bundles on Hulu feature is enabled.
 */
export const getShouldShowLiveTvLink = createSelector(
  [getIsDuoBundlesFeatureEnabled],
  isDuoBundlesFeatureEnabled => {
    return isDuoBundlesFeatureEnabled;
  }
);

export const getBundlesForPlan = (state, selectedPlan) => {
  if (!selectedPlan) return [];
  const currentIds = selectedPlan.componentIds;
  const excludedIds = BASE_COMPONENT_IDS.filter(id => !currentIds.includes(id));
  return getPlans(state).filter(
    plan =>
      // find all plans that contain our current component ids
      currentIds.every(id => plan.componentIds.includes(id)) &&
      // remove plans from result that include components we don't use
      !excludedIds.some(id => plan.componentIds.includes(id)) &&
      // make sure we're not returning the currently selected bundle
      currentIds.length < plan.componentIds.length
  );
};

// If there a selected plan, get all related plan options that is available by keying off the selected plan ID
// If no plan selected, return [].
export const getPlanOptions = (state, selectedPlan) =>
  createSelector([getPlans], plans =>
    selectedPlan
      ? plans.filter(planOption => planOption.id === selectedPlan.id)
      : []
  )(state);

export const getBundlesForSelectedPlan = state =>
  getBundlesForPlan(state, getSelectedPlan(state));

export const getBasePlanForBundle = (state, selectedPlan) => {
  if (!selectedPlan) return [];
  const currentIds = selectedPlan.componentIds;
  const basePlanIds = BASE_COMPONENT_IDS.filter(id => currentIds.includes(id));
  return findPlanByComponentIds(state, basePlanIds);
};

export const getBasePlanForSelectedBundle = state =>
  getBasePlanForBundle(state, getSelectedPlan(state));

const getHasUpsellCheckboxAssignment = state =>
  hasAssignment(state, SUMO_5310.CHECKBOXES);
export const getHasUpsellCheckbox = createSelector(
  [getHasUpsellCheckboxAssignment, getFilteredPlans],
  (hasUpsellPageAssignment, filteredPlans) =>
    hasUpsellPageAssignment && filteredPlans.length === 3
);

const getHasUpsellPageAssignment = state =>
  hasAssignment(state, [CAT_277_V3.TREATMENT1, CAT_277_V3.TREATMENT2]);
export const getHasUpsellPage = createSelector(
  [getHasUpsellPageAssignment, getEnabledUpsellPageFlows, getStartPathname],
  (hasUpsellPageAssignment, enabledUpsellPageFlows, startPathname) =>
    hasUpsellPageAssignment &&
    !!enabledUpsellPageFlows.length &&
    enabledUpsellPageFlows.includes(startPathname)
);

const getIsGiftCardFlow = state => !!get(state, 'config.giftCode.value.value');

const getIsSamsungOOTB = state =>
  get(state, 'config.plans[0].subscription.promotion.programId') === 11933;

/**
 * Checks if the the Bundle plans should be available to be selected and therefore
 * if the PlanGroupTabs should be visible so that the user can select the "Bundle & Save"
 * tab.
 *
 * @returns True if the bundles tab should be visible, false otherwise
 */
export const getShowBundleTab = createSelector(
  [
    getPlansHaveDisneySuperBundle,
    getIsPartnerFlow,
    getIsRokuPacFlow,
    getIsLiveOnlyFlow,
    getIsGiftCardFlow,
    getIsSamsungOOTB,
    getHasUpsellCheckbox,
  ],
  (
    plansHaveDisneySuperBundle,
    isPartnerFlow,
    isRokuPacFlow,
    isLiveOnlyFlow,
    isGiftCardFlow,
    isSamsungOOTB,
    hasUpsellCheckbox
  ) =>
    plansHaveDisneySuperBundle &&
    !isPartnerFlow &&
    !isRokuPacFlow &&
    !isLiveOnlyFlow &&
    !isGiftCardFlow &&
    !isSamsungOOTB &&
    !hasUpsellCheckbox
);

/**
 * Gets the group of basic and bundle plans to be displayed on the view whether
 * it is a large view or mobile view.
 *
 * @param plans The array of plans from state.config.plans
 * @param {Boolean} is3Up Determines if we want to show the three-group plan view
 * (NOAH, SASH, LIVE) or a two-group plan (NOAH, SASH)
 * @param {Boolean} isSprintV2Flow Determines if we are in the sprintV2 flow which offers LIVE
 * @returns {Array} Array of the plans filtered by matching the group keys defined
 * inside the function
 */
const getTabsFilteredPlans = (plans, is3Up = false, isSprintV2Flow) => {
  const livePlans = isSprintV2Flow ? [LIVE] : [NOADS_LIVE_DISNEY_ESPN];
  const planConstants = is3Up
    ? [
        SASH,
        PSEUDO_2P,
        ADS_HULU_DISNEY_ESPN,
        NOAH,
        NOADS_HULU_DISNEY_ESPN,
        ...livePlans,
        ADS_LIVE_DISNEY_ESPN,
        DUO_BASIC,
        DUO_PREMIUM,
      ]
    : [SASH, ADS_HULU_DISNEY_ESPN, NOAH, NOADS_HULU_DISNEY_ESPN];

  return filterPlans(plans, ...planConstants);
};

const getMobilePlansOrder = (
  planOrder,
  isSprintV2Flow,
  show3UpView,
  showDuoBundlesOn3Up
) => {
  if (isSprintV2Flow) {
    planOrder = [SASH, LIVE, NOAH];
    // If in 3Up View insert Duo Basic into the second position
    // after Trio Basic insert: Trio Premium, ADS_LIVE_DISNEY_ESPN
  } else if (show3UpView) {
    if (showDuoBundlesOn3Up) {
      planOrder.splice(1, 0, DUO_BASIC, DUO_PREMIUM);
      planOrder.splice(4, 1, ADS_LIVE_DISNEY_ESPN);
    } else {
      planOrder.splice(1, 0, PSEUDO_2P);
      planOrder.splice(3, 1, NOADS_HULU_DISNEY_ESPN, ADS_LIVE_DISNEY_ESPN);
    }
  } else {
    planOrder.splice(3, 1, ADS_LIVE_DISNEY_ESPN, NOAH, NOADS_LIVE_DISNEY_ESPN);
  }
  return planOrder;
};

/**
 * Determines the order that the array of filteredPlans will end up having. This
 * array of keys will be evaluated later on the sortedPlans function.
 *
 * If the user is on a screen with tabs, filter plans to include or exclude disney bundles based
 * on the current tab index. Then add a Live Plan to the end of the list (if in SprintV2 flow,
 * add LIVE plan instead of ADS_LIVE_DISNEY_ESPN). If in the 3Up View return the order:
 * Duo Basic, Trio Basic, Trio Premium
 *
 * If the user is on a screen without tabs (mobile), add live plans to planOrder before and after NOAH plan.
 * If the user is in 3Up View (mobile) return the Duo Basic Card in position 2 and NOAH last
 *
 * @param {String} currentTabIndex currently selected tab if screen has tabs or undefined if mobile screen without tabs
 * @param {Boolean} isSprintV2Flow Determines if we are in the sprintV2 flow which offers LIVE
 * @return {Array} Array of keys for applied order
 */

const getTabsPlansOrder = (
  currentTabIndex,
  isSprintV2Flow,
  show3UpView,
  showDuoBundlesOn3Up
) => {
  let planOrder = [SASH, ADS_HULU_DISNEY_ESPN, NOADS_HULU_DISNEY_ESPN, NOAH];

  if (currentTabIndex) {
    if (currentTabIndex === BUNDLE_PLANS_TAB) {
      // Show Disney bundle plans when in BUNDLE_PLANS_TAB
      planOrder = planOrder.filter(plan => plan.includes('DISNEY')).reverse();

      if (show3UpView) {
        planOrder = showDuoBundlesOn3Up
          ? [DUO_BASIC, ADS_HULU_DISNEY_ESPN, DUO_PREMIUM]
          : [PSEUDO_2P, ADS_HULU_DISNEY_ESPN, NOADS_HULU_DISNEY_ESPN];
      } else {
        planOrder.push(NOADS_LIVE_DISNEY_ESPN);
      }
    } else {
      // Default to BASIC_PLANS_TAB and show basic plans
      planOrder = planOrder.filter(plan => !plan.includes('DISNEY')).reverse();

      // Add Live if in 3up SprintV2 desktop flow, otherwise add SASH Live Bundle
      const livePlans = isSprintV2Flow ? [LIVE] : [ADS_LIVE_DISNEY_ESPN];
      planOrder.push(...livePlans);
    }
  } else {
    // IF ON MOBILE i.e. no current tab
    planOrder = getMobilePlansOrder(
      planOrder,
      isSprintV2Flow,
      show3UpView,
      showDuoBundlesOn3Up
    );
  }

  return planOrder;
};

/**
 * Determines the order that the array of filteredPlans will end up having when the user is in the
 * Tabs on Mobile Experiment HULU-13873. This array of keys will be evaluated later on the
 * sortedPlans function.
 *
 * It starts by setting the default order of the basic and bundle plans in mobile view
 * and then it will evaluate the type of selected index to either remove or keep disney
 * bundled plans. If the view is Desktop, then it will reorder the plans.
 *
 * Finally, for both mobile and desktop, add a Live Plan to the end of the list
 *
 * @param {Boolean} isDesktop Check if the view is desktop or not
 * @param {String} planTabIndex What tab is currently selected
 * @return {Array} Array of keys for applied order
 */
const getTabsOnMobilePlansOrder = (isDesktop, planTabIndex) => {
  let planOrder = [SASH, ADS_HULU_DISNEY_ESPN, NOADS_HULU_DISNEY_ESPN, NOAH];

  if (planTabIndex === BUNDLE_PLANS_TAB) {
    // Show Disney bundle plans when in BUNDLE_PLANS_TAB
    planOrder = planOrder.filter(plan => plan.includes('DISNEY'));
    planOrder = isDesktop ? planOrder.reverse() : planOrder;

    planOrder.push(NOADS_LIVE_DISNEY_ESPN);
  } else {
    // Default to BASIC_PLANS_TAB and show basic plans
    planOrder = planOrder.filter(plan => !plan.includes('DISNEY'));
    planOrder = isDesktop ? planOrder.reverse() : planOrder;

    planOrder.push(ADS_LIVE_DISNEY_ESPN);
  }

  return planOrder;
};

/**
 * If we should override the SASH or DUO_BASIC copy for a sale, do so.
 *
 * @returns plan card copy and terms apply modal details
 */
export const getFlashSaleOverrides = props => {
  if (!props.showFlashSalePlan) return {};

  switch (props.plan.identifier) {
    case SASH: {
      return {
        badgeDescription: 'BLACK FRIDAY OFFER • 90% OFF',
        mobileBadgeDescription: 'BLACK FRIDAY OFFER\n90% OFF',
        description:
          'LIMITED TIME OFFER. Our Hulu (With Ads) plan lets you watch exclusive series, hit movies, Originals, kids shows, and tons more.',
        plan: {
          ...props.plan,
          additionalTerms: [
            'Savings compared to current regular monthly price. Offer for Hulu (With Ads) plan only: $0.99/month for 12 months, then auto-renews at $9.99/month or then-current regular monthly price. Ends 11:59 PM PST on 12/2/24. Cancel anytime, effective at the end of your billing period. No refunds or credits for partial months. New and eligible returning subscribers (who have not been Hulu subscribers in the past 1 month) only; Disney+ Basic and Disney Bundle subscribers are not eligible. Not combinable with any free trial of the Hulu (With Ads) plan or any other promotional offers or pricing (including the Disney Bundle); not redeemable via gift card. Any plan switch after redemption of this offer will result in forfeiture of the discount pricing.',
          ],
        },
      };
    }
    case DUO_BASIC: {
      return {
        badgeDescription: 'BLACK FRIDAY OFFER • 72% OFF',
        mobileBadgeDescription: 'BLACK FRIDAY OFFER\n72% OFF',
        description:
          'LIMITED TIME OFFER. Access endless entertainment with Disney+ (With Ads), and award-winning Hulu Originals with Hulu (With Ads).',
        plan: {
          ...props.plan,
          additionalTerms: [
            'Savings compared to current regular monthly price. Offer for Disney Bundle Duo Basic plan only (includes Hulu (With Ads) and Disney+ (With Ads)): $2.99/month for 12 months, then auto-renews at $10.99/month or then-current regular monthly price. Ends 11:59 PM PST on 12/2/24. U.S. residents, 18+ only. Offer valid for new and eligible returning Hulu and Disney+ subscribers (who have not been Hulu or Disney+ subscribers in the past 1 month); Disney+, ESPN+, Hulu, and Disney Bundle subscribers are not eligible. Cancel anytime, effective at the end of your billing period. No refunds or credits for partial months. Not combinable with any free trial or any other promotional offers or pricing (including the Disney Bundle); not redeemable via gift card. Any plan switch after redemption of this offer will result in forfeiture of the discount pricing.',
            'Access content from each service separately. Location data may be required to watch certain content. Select Hulu content available via Disney+ with valid Disney+ and Hulu subscriptions; additional content only available via Hulu app. Hulu content can be streamed via Disney+ on up to 2 devices simultaneously. Additional app feature and device restrictions apply. For more information, including detailed information on billing and cancelation, please visit the Hulu Help Center.',
          ],
        },
      };
    }
    default: {
      return {};
    }
  }
};

/**
 * Checks if experiment HULU-13876 is active and window size is desktop. We
 * disable the experiment if the Disney Bundles tab is not available.
 *
 * @returns {boolean} True when Plan Select should show the experiment treatment
 */
export const getShowSASHBundleBadge = createSelector(
  [hasSASHBundleBadgeAssignment, getWindowIsDesktop, getShowBundleTab],
  (isSASHBundleBadgeAssignment, isDesktop, showBundleTab) =>
    isSASHBundleBadgeAssignment && isDesktop && showBundleTab
);

export const getLiveTvLinkPlan = state => {
  const plans = getPlans(state);
  return plans.find(plan => plan.identifier === NOADS_LIVE_DISNEY_ESPN);
};

export const getBannerPlan = (plans, programId) => {
  const supportedProgramIds = {
    '600': NOADS_LIVE_DISNEY_ESPN,
    '18504': NOADS_HULU_DISNEY_ESPN,
  };

  return supportedProgramIds[programId]
    ? plans.find(plan => plan.identifier === supportedProgramIds[programId])
    : undefined;
};

/**
 * If we should display an offer in a banner, return the appropriate offer.
 * Relates to experiment HULU_14432.
 *
 * @returns banner config with copy, offer id, and offer plan (if applicable)
 */
export const getBannerOffer = (
  state,
  { filteredPlans, planTabIndex, showBundleTab, windowSizeBreakpoint }
) => {
  let bannerConfig;

  const is3Up = getShow3UpView(state) && filteredPlans.length % 3 === 0;
  const isBundleTab = planTabIndex === BUNDLE_PLANS_TAB;
  const isMobile = windowSizeBreakpoint !== WIDE_DESKTOP;
  const isDuoBundlesEnabled = getIsDuoBundlesFeatureEnabled(state);

  if (is3Up && showBundleTab && (isBundleTab || isMobile)) {
    bannerConfig = isDuoBundlesEnabled ? 'trio_premium_banner' : 'live_banner';
  }

  if (!bannerConfig) {
    return undefined;
  }

  const bannerOfferId = getControlText(state, `${bannerConfig}.cta.value`);

  if (!bannerOfferId) {
    return undefined;
  }

  const unfilteredPlans = getPlans(state);

  return {
    copy: getControlText(state, `${bannerConfig}.body`),
    id: bannerOfferId,
    plan: getBannerPlan(unfilteredPlans, bannerOfferId),
  };
};
